/* Registration */

#frontoffice-root form {
    margin: auto!important;
    max-width: 500px;
    font-family: Arial, Helvetica, sans-serif!important;
}
#frontoffice-root .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 6px !important;
}

#frontoffice-root .form-main-title {
    text-align: center;
    font-size: 24px;
    margin-top: 50px;
    /* color: #093851; */
}

#frontoffice-root .form-description {
    text-align: justify;
    /* margin-bottom: 20px; */
}

#frontoffice-root .form-title {
    display: block !important;
    /* margin-top: 50px !important; */
    font-size: 20px !important;
    line-height: 1.4 !important;
    text-transform: none !important;
    color: #2d2e33 !important;
    letter-spacing: 0 !important;
}

#frontoffice-root .form-title:first-child {
    margin-top:20px!important;
}

#frontoffice-root .form-input {
    text-align: left;
    color: #888888!important;
    background-color: #ffffff !important;
    border-color: #cccccc !important;
    border-radius: 3px !important;
    font-size: 15px !important;
    padding: 10px 10px !important;
    border: 0 none !important;
    border-bottom: 1px solid #e5e5e7 !important;
    transition: .2s ease-in-out !important;
    transition-property: color, background-color, border-color, box-shadow !important;
}

#frontoffice-root .form-button {
    font-size: 15px !important;
}

#frontoffice-root .form-button-parent {
    text-align: center;
}

#frontoffice-root .form-button:hover {
    background-color: #b7a088 !important;
    border-color: #b7a088 !important;
    opacity: .8 !important;
}

#frontoffice-root .form-input-select {
    padding: 5.5px 4px !important;
}

#frontoffice-root .form-input:focus {
    border-color: #093851!important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

/* Payment Modal */

#frontoffice-root .registration-modal-payment-image {
    height: 50px;
}

/* Payment Modal Choice */

#frontoffice-root .registration-modal-choice-parent {
    background-color: #f0f0f0;
    border-radius: 20px;
    padding: 20px;
    
}