/* @import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap'); */

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#frontoffice-root code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#frontoffice-root .flex {
  display: flex;
  align-items: center;
}


#backoffice-root code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%;
  height: 100%;
}

#backoffice-root .container-alert {
  position: fixed;
  bottom: 20px;
  animation: alert-enter-leave 6s forwards;
  max-width: 50%;
  z-index: 10000;
}

@keyframes alert-enter-leave {
  0% { left: -1000px }
  10% { left: 20px }
  90% { left: 20px }
  100% { left: -1000px; }
}

#backoffice-root .flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

#backoffice-root .flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#backoffice-root .flex-space-evently {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}