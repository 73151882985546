#backoffice-root .login-parent {
    width: 100%;
    height: 100%;
}

#backoffice-root .login-parent label {
    color: white;
}

#backoffice-root .login-background-image {
    background-image: url('../images/login_background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    content: '';
}

#backoffice-root .login-background {
    background-color: rgba(12, 12, 12, 0.8);
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
}

#backoffice-root .login-content {
    position: relative;
    width: 500px;
    background-color: #2b2b2b;
    border-radius: 10px;
    padding: 105px 20px 0px 20px;
}

#backoffice-root .login-header {
    width: 300px;
    position: absolute;
    top: -80px;
    left: 50%;
    transform: translate(-50%);
    display: flex;
    justify-content: center;
    background-color: var(--backoffice-primary-background-color);
    border-radius: 10px;
}

#backoffice-root .login-logo {
    width: 200px;
}

#backoffice-root .login-input,
#backoffice-root .login-input input {
    color: white;
    background-color: #2b2b2b !important;
}

#backoffice-root .login-input .ant-input-password-icon {
    color: white;
}