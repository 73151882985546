#frontoffice-root .ant-select-selector {
    border: none !important;
}

#frontoffice-root .ant-layout-header {
    background-color: white;
}
  
#frontoffice-root .ant-layout-footer {
    background: #093851;
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 15px;
    color: rgba(255,255,255,.7);
}
  
#frontoffice-root .ant-layout {
    max-width: 1560px;
    margin-left: auto!important;
    margin-right: auto!important;
    border: 20px solid white;
    border-top: 0px;
}

#frontoffice-root .ant-layout-content {
    background-color: white;
}

#backoffice-root .ant-layout-header {
    height: 64px;
    padding: 0 50px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 64px;
    background: white;
    }
#backoffice-root .ant-layout-footer{
    padding: 24px 50px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    background: white;
}